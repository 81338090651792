<template>
    <div
        :id="`tab.${id}`"
        class="tab-item"
        :class="[isVertical ? 'vertical' : 'horizontal', { active: isActive }]"
        tabindex="0"
        role="tab"
        :aria-selected="isActive ? 'true' : 'false'"
        :aria-controls="`tabpanel.${id}`"
        @click="$emit('toggle', $event)"
        @keydown.enter.prevent="$emit('toggle', $event)"
    >
        <slot />
        <div class="tab-item-divider" />
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'
    import { IconArrow } from 'custom/icons'

    @Component({
        components: {
            IconArrow
        }
    })
    export default class TabComponent extends Vue {
        @Prop({ type: String, required: true }) id!: string
        @Prop({ type: String, required: false }) activeId?: string
        @Prop({ type: Boolean, required: false }) isVertical?: boolean

        get isActive(): boolean {
            return this.activeId === this.id
        }
    }
</script>

<style scoped>
    @import 'variables.css';

    .tab-item {
        position: relative;
        cursor: pointer;
        transition: border 0.3s ease;
        min-height: 52px;
        min-width: 68px;
        padding: 12px 8px;
    }

    .tab-item.vertical {
        margin-top: 16px;
    }

    .tab-item.vertical:first-child {
        margin-top: 0;
    }

    .tab-item:hover {
        background-color: rgba(237, 165, 15, 0.15);
    }

    .tab-item.horizontal {
        border-bottom: 3px solid var(--color-grey-light);
    }

    .tab-item.horizontal .tab-item-divider {
        display: none;
    }

    .tab-item.vertical:last-child .tab-item-divider {
        display: none;
    }

    .tab-item.vertical {
        width: 100% !important;
        text-align: left !important;
    }

    .tab-item.vertical.active {
        border-right: 3px solid var(--color-branding-dark);
        border-color: var(--color-branding-dark);
    }

    .tab-item.horizontal.active {
        border-color: var(--color-branding-dark);
    }

    .tab-item-divider {
        position: absolute;
        width: 90%;
        bottom: -9px;
        border-bottom: 3px solid var(--color-grey-light);
    }

    .icon-arrow-right {
        vertical-align: middle;
        height: 25px;
        color: var(--color-grey-dark);
    }
</style>
